<template>
	<div class="bg-light">
    <el-form :model="formData" status-icon :rules="rules" ref="form" label-width="auto" class="margin-top">
        <el-form-item label="平台服务协议" prop="service">
            <editor v-model="formData.service"/>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="submitForm('form')">确定</el-button>
            <el-button type="" @click="$router.back()">返回</el-button>
        </el-form-item>
    </el-form>
	</div>
</template>

<script>
export default {
    name: "add",
    data() {
        return {
            formData: {
                // service: '',
                service:'',
                // secret:''
            },
            rules: {
                // service:[{required:true}],
                service:[{required:true}],
                // secret:[{required:true}],
            },
        };
    },
    mounted(){
        this.getDetail();
    },
    methods: {
        submitForm(formName) {
            let _this = this;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    console.log(_this.formData);
                    _this.$http.request({
                        url:'/SystemSetting/edit',
                        datas:_this.formData,
                        success:(res)=>{
                            if(res === 'ok'){
                                _this.$message.success('修改成功');
                            }else{
                                _this.$message.error('修改失败');
                            }
                            // _this.$message.info(res.msg);
                            // _this.$router.replace({path: '/cate/list'})
                        }
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        getDetail() {
            let _this = this;

            let _skeys = [];

            for(let k in this.formData){
                _skeys.push(k);
            }

            _this.$http.request({
                url:'/SystemSetting/getSystem',
                datas:{skey:_skeys},
                success:(res)=>{
                    _this.formData = res;
                    // console.log(res);
                    // _this.$message.info(res.msg);
                    // _this.$router.replace({path: '/cate/list'})
                }
            });
        }
    }
}
</script>
